import React from "react"
import './index.scss';

import {ReactComponent as Linkedin} from '../../img/linkedin.svg';
import {ReactComponent as Facebook} from '../../img/facebook.svg';
import {ReactComponent as Instagram} from '../../img/instagram.svg';


const TitleWithSocial = ({title, hasSocials, socials}) => {
    return (
        <div className="title-with-social__container">
            {
                title && <h2 className="title">{title}</h2>
            }
            {
                hasSocials &&
                <div className="socials__container">
                    {
                        socials.map((social, key) => {
                            return (
                                <a href={social.url}
                                   key={key}
                                   target="_blank">
                                    <div className="social-picto">
                                        {
                                            social.text === "Linkedin" ?
                                                <Linkedin/> : social.text === "Facebook" ?
                                                <Facebook/> : social.text === "Instagram" ?
                                                    <Instagram/> : ""
                                        }
                                    </div>
                                </a>
                            )
                        })
                    }
                </div>
            }

        </div>
    )
}

export default TitleWithSocial
