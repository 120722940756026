import React from "react";
import './index.scss';

export const Loader = () => {
    return (
        <div className="loader">
            <div className="loader-spinner">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>
        </div>
    )
};

export const LoaderPage = ({ load }) => {
    return (
        // <div className="loader-page" data-load={load}>
        //     <div className="loader-page_center">
        //         <div className="loader-page_logo">&nbsp;</div>
        //     </div>
        // </div>
        <div className={`loader-page ${load ? 'loaded' : ''}`} data-load={load}>
            <div className="loader-spinner">
                <div>&nbsp;</div>
                <div>&nbsp;</div>
                <div>&nbsp;</div>
            </div>
        </div>
    );
};