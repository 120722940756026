import React, {useState, useEffect, useRef} from "react"
import './index.scss';

import GlobalContainer from '../../containers/GlobalContainer/index'


function TitleAnimate() {

    const [scrollY, setScrollY] = useState();
    const box1 = useRef(null);

    useEffect(
        () => {
            let boxPositionBottomMax = 500;

            const handleScroll = () => {
                let boxPositionBottom = (window.innerHeight - box1.current.offsetHeight) - box1.current.getBoundingClientRect().top;
                if (boxPositionBottom > 0 && boxPositionBottom < boxPositionBottomMax) {
                    setScrollY(boxPositionBottom);
                }
            };
            window.addEventListener("scroll", handleScroll);
            document.addEventListener('touchmove', handleScroll);
            return () => {
                window.removeEventListener("scroll", handleScroll);
                document.removeEventListener('touchmove', handleScroll)
            }

        }, []
    );

    return (
        <div className="title-animate__wrapper">
            <GlobalContainer>
                <div className="title-animate__container">
                    <div className="spacer-top"></div>
                    <div className="title-content">
                        <div ref={box1} className="box box1">
                            <h2>social <span>x</span></h2>
                            <h2>content</h2>
                        </div>
                        <div className="box box2" style={{
                            transform: `translateY(${((scrollY)) * 0.15}%)`,
                            WebkitTransform: `translateY(${((scrollY)) * 0.15}%)`
                        }}>
                            <h2>content</h2>
                        </div>
                        <div className="box box3" style={{
                            transform: `translateY(${((scrollY)) * 0.3}%)`,
                            WebkitTransform: `translateY(${((scrollY)) * 0.3}%)`
                        }}>
                            <h2>content</h2>
                        </div>
                        <div className="box box4" style={{
                            transform: `translateY(${((scrollY)) * 0.4}%)`,
                            WebkitTransform: `translateY(${((scrollY)) * 0.4}%)`
                        }}>
                            <h2>content</h2>
                        </div>
                        <div className="box box5" style={{
                            transform: `translateY(${((scrollY)) * 0.45}%)`,
                            WebkitTransform: `translateY(${((scrollY)) * 0.45}%)`
                        }}>
                            <h2>content</h2>
                        </div>
                    </div>
                </div>
                <div className="spacer-bottom"></div>
            </GlobalContainer>
        </div>

    )
}

export default TitleAnimate
