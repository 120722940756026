import React from "react"
import './index.scss';



function TwoVisualsContainer(props) {

    return (

        <div className="two-visuals__container">
            {props.children}
        </div>

    )
}

export default TwoVisualsContainer
