import React from 'react';
import './index.scss';

const Mailto = ({mail}) => {
    return (
        <div className="mailto__container">
            <a href={`mailto:${mail}`}>{mail}</a>
        </div>
    )
}

export default Mailto