import React from "react"
import './index.scss';
import GlobalContainer from "../../../containers/GlobalContainer";

import ReactPlayer from 'react-player'
import {ReactComponent as Play} from '../../../img/play.svg';

const TextVideo = ({texte, src, thumbnail, affichage_texte}) => {
    function getID(url) {
        let tab = url.split("/");
        if (tab.length > 0) {
            return tab[tab.length - 1]
        } else {
            return "";
        }
    }

    return (

        <GlobalContainer max={true}>
            <div className={`text-video__container ${affichage_texte}`}>
                <div className="content__video-inner">
                    {
                        src.toLowerCase().indexOf("vimeo.com") > -1 ?
                            <div className="content__video-inner-vimeo">
                                {
                                    window.tarteaucitron.state && window.tarteaucitron.state.vimeo ?
                                        (
                                            <iframe
                                                src={'//player.vimeo.com/video/' + getID(src) + '?autoplay=1&loop=1&background=1'}
                                                title="video"
                                                width="100%" height="100%" frameBorder="0"
                                                className="background-video" allow="autoplay; fullscreen"
                                                allowFullScreen></iframe>
                                        ) : (
                                            <div className="vimeo_player background-video"
                                                 data-videoid={getID(src)}
                                                 data-width="100%" data-height="100%"
                                            >
                                                <div className="video_cookie"
                                                     onClick={() => {
                                                         window.tarteaucitron.cookie.create('vimeo', true);
                                                         window.tarteaucitron.userInterface.color('vimeo', true);
                                                         window.tarteaucitron.state.vimeo = true;
                                                     }}
                                                     style={{backgroundImage: `url(${thumbnail ? thumbnail : ''})`}}>
                                                    <div
                                                        dangerouslySetInnerHTML={{__html: window.tarteaucitron.engage("vimeo")}}></div>
                                                </div>
                                            </div>
                                        )
                                }
                            </div>
                            :
                            <ReactPlayer
                                className="content__video-player"
                                width="100%"
                                height="100%"
                                url={[
                                    {src: src, type: 'video/mp4'},
                                ]}
                                light={thumbnail}
                                playing
                                controls
                                playIcon={<Play/>}
                            />
                    }

                </div>
                <div className="content__textVideo-content" dangerouslySetInnerHTML={{__html: texte}}>
                </div>
            </div>
        </GlobalContainer>


    )
}

export default TextVideo