import React, { useEffect, useRef } from "react"
import './index.scss';

import Fade from 'react-reveal/Fade';

import GlobalContainer from '../../containers/GlobalContainer/index'

function BlockTextAgence(props) {

    const strikedEl = useRef(null);
    // const [inView, setInView] = useState(false);

    useEffect(

        () => {
            const isInview = () => {
                let positionBottom = (window.innerHeight - strikedEl.current.offsetHeight) - strikedEl.current.getBoundingClientRect().top + strikedEl.current.offsetHeight;
                if (positionBottom > 100) {
                    strikedEl.current.classList.add("in-view");

                }
                // else {
                // strikedEl.current.classList.remove("in-view");
                // }

                // setInView(strikedEl.current.classList.contains('in-view'))
            };
            isInview();
            window.addEventListener("scroll", isInview);
            return () => window.removeEventListener("scroll", isInview);
        }, []
    );


    return (
        <div className="block-text-agence__container" ref={strikedEl}>
            <GlobalContainer>
                <Fade>
                    <div>
                        <h3 className="strike-text">{props.strikedTextFirst}</h3><br />
                        <h3 className="strike-text second-line">{props.strikedTextSecond}</h3>
                    </div>
                </Fade>
                <div className="text-agence__container">
                    <Fade bottom delay={600}>
                        <h2 className="text-agence-title">{props.title}</h2>
                    </Fade>

                    <Fade bottom delay={600}>
                        <div dangerouslySetInnerHTML={{ __html: props.texts }}></div>
                    </Fade>

                </div>
            </GlobalContainer>
        </div>

    )
}

export default BlockTextAgence
