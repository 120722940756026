import React, { useState } from "react"
import './index.scss';
import {Link} from "react-router-dom"

import GlobalContainer from '../../containers/GlobalContainer/index'
import MenuBubble from '../../components/MenuBubble/index.js'

const SubMenu = ({ itemsMenu, title, extraClass, onClick }) => {
    const [categorySelectedPosition, setCategorySelectedPosition] = useState(0);
    let url = "";
    if (itemsMenu && itemsMenu.length > 0) {
        url = itemsMenu[0].url;
    }

    return (
        <div className={`expertises-details__container ${extraClass}`}>
            <GlobalContainer>
                <div style={{ maxWidth: 600, margin: 'auto' }}>
                    {
                        url === "" ?
                            (
                                <div className="title">{title}</div>
                            ) : (
                                <div className="title"><Link to={url} >{title}</Link></div>
                            )
                    }
                    <MenuBubble getClickedKey={setCategorySelectedPosition} itemsMenu={itemsMenu} onClick={onClick} />
                </div>
            </GlobalContainer>
        </div>
    )
}

export default SubMenu;
