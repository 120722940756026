import React, { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { updateGlobalStorePage } from "../../store/action";
import { useLocation } from 'react-router-dom';
import useApi from '../../utils/useApi';
import './index.scss';
// import videoSrc from '../../videos/video-test.mp4';

// import config from '../../config/agence.json'

import ComponentByJSON from "../../utils/createComponentsByJSON";


// COMPONENTS
import { Loader } from "../../components/Loaders";
import Metatags from "../../components/MetasTags";
import Eye from "../../components/Eye";

// CONTAINER
import GlobalContainer from '../../containers/GlobalContainer/index.js'





const Agence = ({ _uid }) => {
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('page-agence'));
    }, [dispatch]);

    const location = useLocation();
    const [dataFetch, isLoaded] = useApi({ slug: location.pathname, name: 'page', _uid });
    const { metas, title, videoUrl, flexibles, showreel } = dataFetch;
    // const isLoaded = true;
    // const { metas, title, videoUrl, flexibles, showreel } = config;

    return (
        <>
            {isLoaded ? (
                <>
                    <Metatags {...metas} />
                    <Eye
                        cursorText={showreel.cursorText}
                        showreelType={showreel.choix_type_video}
                        showreelUrl={showreel.url}
                    />
                    <div className="agence__container">
                        <GlobalContainer>
                            <div className="agence-title__container">
                                <h2 className="title-page">{title}</h2>

                                {videoUrl &&
                                    <div className="title-video__container">
                                        <video loop muted autoPlay playsInline={true}>
                                            <source src={videoUrl} type="video/mp4" />
                                        </video>
                                    </div>
                                }

                            </div>
                        </GlobalContainer>

                        {
                            flexibles && flexibles.map((block) => ComponentByJSON(block))
                        }

                    </div>
                </>
            ) : <Loader />
            }
        </>
    )
}

export default Agence;
