import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalStorePage } from "../../store/action";
import { useLocation } from 'react-router-dom';
import useApi from '../../utils/useApi';
import './index.scss';

// COMPONENTS
import { Loader } from "../../components/Loaders";
import Metatags from "../../components/MetasTags";
import TitleWithSocial from '../../components/TitleWithSocial/index.js'
import Mailto from '../../components/Mailto/index.js'

// CONTAINER
import GlobalContainer from '../../containers/GlobalContainer/index'

const Contact = ({ _uid }) => {
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('page-contact'));
    }, [dispatch]);

    const location = useLocation();
    const [dataFetch, isLoaded] = useApi({ slug: location.pathname, name: 'page', _uid });
    const { metas, title, hasSocial, mail } = dataFetch;

    const globalStore = useSelector(state => state.global);
    const { social_networks, addresses } = globalStore.global;

    return (
        <>
            {isLoaded ? (
                <>
                    <Metatags {...metas} />
                    <GlobalContainer>
                        <TitleWithSocial title={title} socials={social_networks.links}
                            hasSocials={hasSocial} />
                        <Mailto mail={mail} />
                        <div className="contact__container">
                            {
                                addresses.map((address, key) => {
                                    return (
                                        <div key={key} className="contact-block">
                                            <div className="contact-img">
                                                <img src={address.image} alt="" />
                                            </div>
                                            <h2 className="contact-city">{address.city}</h2>
                                            <p>{address.street}</p>
                                            <p>{address.zipCode}</p>
                                            <p style={{ marginTop: 20 }}>{address.phone}</p>
                                        </div>
                                    )
                                })
                            }

                        </div>
                        
                    </GlobalContainer>
                </>
            ) : <Loader />
            }
        </>
    );
}

export default Contact;
