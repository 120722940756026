import React, { useState } from "react";
import "./index.scss";

const JobComponent = ({ job, see_offer, apply, download_offer }) => {
  const [show, setShow] = useState(false);
  return (
    <div className="job-offer">
      <div dangerouslySetInnerHTML={{ __html: job.title }}></div>

      {show && (
        <div className="job-offer-content">
          <div
            className="job-offer-content-intro"
            dangerouslySetInnerHTML={{ __html: job.intro }}
          ></div>
          <div
            className="job-offer-content-content"
            dangerouslySetInnerHTML={{ __html: job.content }}
          ></div>
          {job.apply_link && (
            <div className="job-offer-content-button">
              <a target={job.apply_link.target} href={job.apply_link.url}>
                <p>{apply}</p>
              </a>
            </div>
          )}
          {job.job_document && (
            <div className="job-offer-content-download">
              <a href={job.job_document} download>
                <div></div>
                <p>{download_offer}</p>
              </a>
            </div>
          )}
        </div>
      )}

      {!show && (
        <div
          className="job-offer-description"
          onClick={() => {
            setShow(true);
          }}
        >
          <div className="job-offer-description-plus">
            <span>+</span>
          </div>
          <p>{see_offer}</p>
        </div>
      )}
      {show && (
        <div className="job-offer-description">
          <div
            className="job-offer-description-plus"
            onClick={() => {
              setShow(false);
            }}
          >
            <span>-</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobComponent;
