import React, {useLayoutEffect, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {updateGlobalStorePage} from "../../store/action";
import {useLocation} from 'react-router-dom';
import useApi from '../../utils/useApi';
import {Link} from "react-router-dom"
import './index.scss';

// import config from '../../config/home.json'


import Fade from 'react-reveal/Fade';
// COMPONENTS
import {Loader, LoaderPage} from "../../components/Loaders";
import Metatags from "../../components/MetasTags";
import Banner from '../../components/Banner';
import ImageOverlay from '../../components/ImageOverlay';
import TitleAnimate from '../../components/TitleAnimate';
import TitleAnimateCanvas from '../../components/TitleAnimateCanvas';
import MenuBubble from '../../components/MenuBubble';

// CONTAINER
import TwoVisualsContainer from '../../containers/TwoVisualsContainer/index.js'
import GlobalContainer from '../../containers/GlobalContainer/index';


const Homepage = ({_uid}) => {
    let dispatch = useDispatch();
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [categorySelectedPosition, setCategorySelectedPosition] = useState(0);
    useEffect(() => {
        dispatch(updateGlobalStorePage('page-home'));
    }, [dispatch]);

    const location = useLocation();
    const [dataFetch, isLoaded] = useApi({slug: location.pathname, name: 'home', _uid});
    const {metas, banner, blockTwoVisuals, allProjects, menuBlock} = dataFetch;
    // const isLoaded = true;
    // const { metas, banner, blockTwoVisuals, allProjects, menuBlock } = config;

    function useWindowSize() {
        const [size, setSize] = useState(window.innerWidth);
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth);
            }

            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const getWidth = useWindowSize();

    function renderPhoto() {
        if (getWidth < 764) {
            return banner.photosSrc.mobile
        } else if (getWidth < 1024) {
            return banner.photosSrc.tablet
        } else {
            return banner.photosSrc.desktop
        }
    }

    function renderVideo() {
        if (getWidth < 764) {
            return banner.videosSrc.mobile
        } else if (getWidth < 1024) {
            return banner.videosSrc.tablet
        } else {
            return banner.videosSrc.desktop
        }
    }

    return (
        <>
            {isLoaded ? (

                <>
                    <LoaderPage load={videoLoaded}/>
                    <Metatags {...metas} />
                    <Banner
                        title={banner.titre}
                        backgroundVideo={banner.video}
                        srcVideo={renderVideo()}
                        srcPhoto={renderPhoto()}
                        typeVideo={banner.choix_type_video}
                        srcVimeo={banner.videoVimeo}
                        setVideoLoaded={setVideoLoaded}
                    />

                    {/* {
                        menuBlock.map((menu, key) => {
                            return (
                                <div key={key} className={`expertises-details__container ${menu.subMenu.containerColor}`}>
                                    <TitleAnimate />
                                    <GlobalContainer>
                                        <Fade delay={500}>
                                            <MenuBubble
                                                getClickedKey={setCategorySelectedPosition}
                                                itemsMenu={menu.subMenu.items} />
                                        </Fade>
                                    </GlobalContainer>
                                </div>
                            )
                        })
                    } */}

                    <div className={`expertises-details__container homepage ${menuBlock[0].subMenu.containerColor}`}>
                        <TitleAnimateCanvas/>
                        <GlobalContainer>
                            <Fade delay={500}>
                                <MenuBubble
                                    getClickedKey={setCategorySelectedPosition}
                                    itemsMenu={menuBlock[0].subMenu.items}/>
                            </Fade>
                        </GlobalContainer>
                    </div>
                    <div className={`expertises-details__container ${menuBlock[1].subMenu.containerColor}`}>
                        <TitleAnimate/>
                        <GlobalContainer>
                            <Fade delay={500}>
                                <MenuBubble
                                    getClickedKey={setCategorySelectedPosition}
                                    itemsMenu={menuBlock[1].subMenu.items}/>
                            </Fade>
                        </GlobalContainer>
                    </div>

                    {
                        blockTwoVisuals.map((e, key) => {

                            return (
                                <TwoVisualsContainer key={key}>
                                    <ImageOverlay title={e.visualLeft.title} url={e.visualLeft.url}
                                                  imgSrc={e.visualLeft.src}
                                                  textHover={e.visualLeft.titleHover}
                                                  percentSize={e.visualLeft.percentSize}
                                                  overlayColor={e.visualLeft.color}/>
                                    <ImageOverlay title={e.visualRight.title} url={e.visualRight.url}
                                                  imgSrc={e.visualRight.src}
                                                  textHover={e.visualRight.titleHover}
                                                  percentSize={e.visualRight.percentSize}
                                                  overlayColor={e.visualRight.color}/>
                                </TwoVisualsContainer>
                            )
                        })
                    }
                    <div className="hp-link__container">
                        <Link to={allProjects.link} className="hp-link">{allProjects.text}</Link>
                    </div>
                </>
            ) : <Loader/>
            }
        </>
    );
};

export default Homepage;
