import React from 'react';
import GlobalContainer from "../containers/GlobalContainer";
// import { Loader } from '../loaders/loader.jsx';

import Intro from "../components/Flexibles/Intro";
import Video from "../components/Flexibles/Video";
import Wysiwyg from "../components/Flexibles/Wysiwyg";
import TextImage from "../components/Flexibles/TextImage";
import TextVideo from "../components/Flexibles/TextVideo";
import Images from "../components/Flexibles/Images";
import BlockTextAgence from "../components/BlockTextAgence";
import BlockTextAgenceStrong from "../components/BlockTextAgenceStrong";
import SliderSwiper from "../components/SliderSwiper";
import SliderSwiperAgence from "../components/SliderSwiperAgence";


/**
 * Déclaration de la corresspondance nom reçu / nom du composant
 *
 */
const DynamicComponents = {
    intro: Intro,
    video: Video,
    wysiwyg: Wysiwyg,
    textImage: TextImage,
    videoTexte: TextVideo,
    images: Images,
    slider: SliderSwiper,
    blockTextAgence: BlockTextAgence,
    blockTextAgenceStrong: BlockTextAgenceStrong,
    sliderSwiper: SliderSwiper,
    sliderSwiperAgence: SliderSwiperAgence
};

/**
 * ## Generation des composants dynamiquement suivant JSON
 * @param {object} block
 * cf : https://www.storyblok.com/tp/react-dynamic-component-from-json
 */
const ComponentByJSON = (block) => {
    if (typeof DynamicComponents[block.component] !== 'undefined') {
        return React.createElement(DynamicComponents[block.component], {
            key: block._uid,
            ...block.datas
        });
    }
    // component doesn't exist yet
    // return React.createElement(() => <Loader/>, {key: block._uid});
    return React.createElement(() => <GlobalContainer max={true}>{block.component} : Not yet or wrong
        name!</GlobalContainer>, { key: block._uid });
};
export default ComponentByJSON;