import React from "react"
import ReactDOM from 'react-dom';
import './index.scss';

class canvasDraw extends React.Component {

    init() {
        this.WIDTH_BASIC = 140;
        this.HEIGHT_BASIC = 79;
        this.RATIO = 10;
        this.RATIOMOBILE = window.innerWidth < 768 ? 0.65 : 1;

        this.PERCENT = 0.1;
        this.TIMER_DECALAGE = 4000;
        this.TIMER_REPLACAGE = 2000;
        this.TIMING = this.TIMER_DECALAGE * 10;

        this.NB_SPOT_SELECTED = 30;

        this.containerCanvas = ReactDOM.findDOMNode(this);
        //this.containerCanvas = document.querySelector(".animparticule")

        if (this.containerCanvas) {
            this.canvas = document.createElement("canvas");
            this.canvas.classList.add("animparticule__canvasbasic");
            this.canvas.height = Math.floor(this.HEIGHT_BASIC * this.RATIOMOBILE);
            this.canvas.width = Math.floor(this.WIDTH_BASIC * this.RATIOMOBILE);
            this.containerCanvas.appendChild(this.canvas);
            this.context = this.canvas.getContext("2d");
            this.context.imageSmoothingEnabled = false;

            this.spots = [];

            this.canvasMatrice = document.createElement("canvas");
            this.canvasMatrice.classList.add("animparticule__canvasfinal");
            // this.canvasMatrice.height = Math.round(this.canvas.height * this.RATIO);
            // this.canvasMatrice.width = Math.round(this.canvas.width * this.RATIO);

            this.canvasMatrice.width = this.containerCanvas.clientWidth;
            this.canvasMatrice.height = (this.canvasMatrice.width * this.canvas.height) / this.canvas.width;
            this.RATIO = this.canvasMatrice.width / this.canvas.width;
            this.RATIO_MORE = Math.floor((this.RATIO - 1) * 0.5);

            this.containerCanvas.appendChild(this.canvasMatrice);
            this.contextMatrice = this.canvasMatrice.getContext("2d");
            this.contextMatrice.fillStyle = "#00fff4";

            this.isHover = false;
            this.canvasMatrice.addEventListener("mouseenter", this.mouseenter.bind(this));
            this.canvasMatrice.addEventListener("mousemove", this.mousemove.bind(this));
            this.canvasMatrice.addEventListener("touchmove", this.touchmove.bind(this));
            this.canvasMatrice.addEventListener("mouseleave", this.mouseleave.bind(this));
            this.canvasMatrice.addEventListener("touchend", this.mouseleave.bind(this));

            this.addString("craft x platform");
            this.addAnimation();
            this.renderGraphics();
        }
    }

    mouseenter() {
        this.isHover = true;
    }

    mouseleave() {
        this.curseurX = Infinity;
        this.curseurY = Infinity;
        this.isHover = false;
    }

    mousemove(e) {
        this.curseurX = e.offsetX;
        this.curseurY = e.offsetY;
    }

    touchmove(e) {
        //e.preventDefault();
        this.curseurX = e.changedTouches[0].pageX - this.canvasMatrice.getBoundingClientRect().left;
        this.curseurY = e.changedTouches[0].pageY - this.canvasMatrice.getBoundingClientRect().top;
    }

    addString(texte) {
        this.texte1Y = this.canvas.height * 0.375;
        this.texte2Y = this.canvas.height * 0.65;

        this.context.font = "bold " + Math.floor(25 * this.RATIOMOBILE) + "px ITCavantGarde";
        let textes = texte.split("x ");
        textes[0] += "x";
        this.context.fillText(textes[0], Math.floor(19 * this.RATIOMOBILE), this.texte1Y);
        this.context.fillText(textes[1], Math.floor(19 * this.RATIOMOBILE), this.texte2Y);

        let imageData = this.context.getImageData(0, 0, this.canvas.width, this.canvas.height);
        let data = imageData.data;

        for (let i = 0; i < this.canvas.width; i++) {
            for (let j = 0; j < this.canvas.height; j++) {
                let index = j * this.canvas.width + i;
                if (data[index * 4 + 3] > 0) {
                    let type = j <= this.texte1Y * 1.05 ? "circle" : "baton";
                    if (type === "circle" && i > this.canvas.width * 0.55) {
                        type = Math.random() < 0.45 ? "circle" : "baton";
                    }
                    let decalage = Math.floor(8 * Math.random())
                    this.spots.push({
                        x: this.RATIO * i,
                        y: this.RATIO * j,
                        startReplacageX: this.RATIO * i,
                        startReplacageY: this.RATIO * j,
                        decalage,
                        homeX: this.RATIO * i,
                        homeY: this.RATIO * j,
                        vecteurX: 0,
                        vecteurY: 0,
                        type,
                        fakeTransition: null,
                        indexintab: this.spots.length,
                        widthBaton: Math.ceil(this.RATIOMOBILE * this.RATIOMOBILE * (this.RATIO - 2 + 2 * decalage)),
                        widthCircle: Math.ceil(this.RATIOMOBILE * this.RATIO_MORE),
                        height: Math.floor(this.RATIOMOBILE * this.RATIO / 5 < 1 ? 1 : this.RATIOMOBILE * this.RATIO / 5)
                    });
                }
            }
        }
    }

    //On va creer des cases dans le canvas, dans chaque case on va choisir un point au hasard auquel on associera X points dans un rayon proche. Ces points voyageront entre eux.
    addAnimation() {
        const RADIUS_PROXIMITY = 10;

        const NB_COL = 10;
        const NB_LINE = 1;

        let infos = {
            minX: Infinity,
            minY: Infinity,
            maxX: 0,
            maxY: 0
        }

        //Detection du pixel le plus haut, le plus bas, le plus a gauche, le plus a droite
        this.spots.forEach(spot => {
            infos.minX = (spot.x < infos.minX ? spot.x : infos.minX);
            infos.minY = (spot.x < infos.minY ? spot.y : infos.minY);
            infos.maxX = (spot.x > infos.maxX ? spot.x : infos.maxX);
            infos.maxY = (spot.x > infos.maxY ? spot.y : infos.maxY);
        });

        const TRANCHE_COL = (infos.maxX - infos.minX) / NB_COL;
        const TRANCHE_LINE = (infos.maxY - infos.minY) / NB_LINE;

        let randomTab = [];

        //Pour chaque case, on choisis un point.
        for (let i = 0; i < NB_LINE; i++) {
            for (let j = 0; j < NB_COL; j++) {

                let spots = this.spots.filter(spot => {
                    let isX = (spot.x > infos.minX + j * TRANCHE_COL && spot.x < infos.minX + (j + 1) * TRANCHE_COL);
                    let isY = (spot.y > infos.minY + i * TRANCHE_LINE && spot.y < infos.minY + (i + 1) * TRANCHE_LINE);
                    return isX && isY;
                })

                if (spots.length > this.NB_SPOT_SELECTED) {
                    let selectedSpots = [];

                    let decalageTransition = Math.floor(NB_COL * NB_LINE * Math.random());
                    while (randomTab.indexOf(decalageTransition) > -1) {
                        decalageTransition = Math.floor(NB_COL * NB_LINE * Math.random());
                    }
                    randomTab.push(decalageTransition);

                    for (let k = 0; k < this.NB_SPOT_SELECTED; k++) {
                        let newSpot = Math.floor(spots.length * Math.random());
                        while (spots[newSpot].done) {
                            newSpot = Math.floor(spots.length * Math.random());
                        }
                        spots[newSpot].done = true;
                        spots[newSpot].index = selectedSpots.length;
                        spots[newSpot].decalageTransition = decalageTransition
                        selectedSpots.push(spots[newSpot]);
                    }

                    selectedSpots.forEach(spot => spot.siblings = selectedSpots);
                }
            }
        }
    }

    drawLetter(t) {
        for (let i = 0; i < this.spots.length; i++) {
            let sp = this.spots[i];

            let decalage = sp.decalage;
            //let randomRotation = Math.abs(Math.cos(i * i * 1.5));
            let randomRotation = Math.abs(Math.cos(i * 150));

            let infosTiming = this.calculeTiming(t, sp.decalageTransition);
            let transition = infosTiming.currentTransition;
            let transitionGeneral = infosTiming.generalTransition;

            let current = sp;
            let next = sp;

            if (sp.siblings) {
                current = sp.siblings[(sp.index + infosTiming.currentIndex + this.NB_SPOT_SELECTED) % this.NB_SPOT_SELECTED];
                next = current.siblings[(sp.index + infosTiming.currentIndex + 1 + this.NB_SPOT_SELECTED) % this.NB_SPOT_SELECTED];
            }

            this.updateMouvementWithGravity(current, next, transition, transitionGeneral);

            if (sp.type === "circle") {
                this.drawCercle(current.x, current.y, current.widthCircle);
            } else if (sp.type === "baton") {
                this.drawBaton(current.x, current.y, decalage, randomRotation, transition, current.widthBaton, current.height);
            }
        }

        this.contextMatrice.fill();
    }

    updateMouvementWithGravity(current, next, transition, transitionGeneral) {
        let distanceX = this.curseurX - current.x;
        let distanceY = this.curseurY - current.y;

        let distance = (distanceX * distanceX + distanceY * distanceY);
        let force = 100 / distance;

        if (force > 0.01) {
            current.fakeTransition = null;

            if (force > 0.1) {
                force = 0.1;
            }

            current.vecteurX = (current.vecteurX + 0.15 * force * distanceX) * 0.999;
            current.vecteurY = (current.vecteurY + 0.15 * force * distanceY) * 0.999;

            current.x += current.vecteurX;
            current.y += current.vecteurY;
        } else {
            this.updateMouvement(current, next, transition, transitionGeneral);
        }
    }

    showlog(spot, texte) {
        if (spot.indexintab === 0) {
            console.log(texte);
        }
    }

    updateMouvement(current, next, transition, transitionGeneral) {
        let startX = current.homeX;
        let startY = current.homeY;

        if (current.fakeTransition !== null || current.vecteurX !== 0 || current.vecteurY !== 0) {
            if (current.fakeTransition === null) {
                current.vecteurX = 0;
                current.vecteurY = 0;

                current.fakeTransition = transitionGeneral;
                current.initX = current.x;
                current.initY = current.y;
            }

            transition = (1 - transitionGeneral) / (1 - current.fakeTransition);
            transition = -transition + 1;

            startX = current.initX;
            startY = current.initY;

            next = current;

            if (transition > 0.999 || transition < 0) {
                transition = 1;
                current.fakeTransition = null;
                startX = current.homeX;
                startY = current.homeY;
            }
        }
        current.x = startX + transition * (next.homeX - startX);
        current.y = startY + transition * (next.homeY - startY);
    }

    drawCercle(posX, posY, width) {
        posX = Math.floor(posX);
        posY = Math.floor(posY);

        if (this.contextMatrice.needTransform) {
            this.contextMatrice.setTransform(1, 0, 0, 1, 0, 0);
            this.contextMatrice.needTransform = false;
        }

        this.contextMatrice.moveTo(posX + this.RATIO_MORE, posY + this.RATIO_MORE);
        this.contextMatrice.arc(posX + this.RATIO_MORE, posY + this.RATIO_MORE, width, 0, 2 * Math.PI)
    }

    drawBaton(posX, posY, decalage, random, transition, width, height) {
        let thisX = Math.floor(posX - decalage);
        let thisY = Math.floor(posY + (this.RATIO - 2) * 0.5);
        let thisW = width;
        let thisH = height;
        let halfThisW = Math.floor(thisW * 0.5);
        let halfThisH = Math.floor(thisH * 0.5);

        let angle = Math.PI * (random + transition * 2);
        var sin = Math.sin(angle);
        var cos = Math.cos(angle);
        this.contextMatrice.setTransform(cos, sin, -sin, cos, thisX + halfThisW, thisY + halfThisH);

        this.contextMatrice.rect(-halfThisW, -halfThisH, thisW, thisH);
        this.contextMatrice.needTransform = true;
    }

    calculeTiming(t, decalage) {
        let generalTimingInStep = t % this.TIMER_REPLACAGE;
        let generalTransition = generalTimingInStep / this.TIMER_REPLACAGE;
        generalTransition = Math.cos(generalTransition * Math.PI);
        generalTransition = (-generalTransition + 1) * 0.5;

        t = (decalage !== undefined ? t + this.TIMER_DECALAGE * decalage : t);
        let currentTimingInStep = t % this.TIMING;
        let currentTransition = (currentTimingInStep < this.TIMING * (1 - this.PERCENT) ? 0 : (currentTimingInStep - this.TIMING * (1 - this.PERCENT)) / (this.TIMING * this.PERCENT));

        currentTransition = Math.cos(currentTransition * Math.PI);
        currentTransition = (-currentTransition + 1) * 0.5;

        let currentIndex = Math.floor(t / this.TIMING) % this.NB_SPOT_SELECTED;

        return {
            currentIndex,
            currentTransition,
            generalTransition
        }
    }

    componentDidMount() {
        this.init();
        this.isPersoMounted = true;
    }

    componentWillUnmount() {
        this.isPersoMounted = false;
    }

    renderGraphics(t = 0) {
        if (this.isPersoMounted) {
            //this.canvasMatrice.width += 0;
            this.contextMatrice.setTransform(1, 0, 0, 1, 0, 0);
            this.contextMatrice.clearRect(0, 0, this.canvasMatrice.width, this.canvasMatrice.height)
            this.contextMatrice.beginPath();
            this.drawLetter(t);
        }
        requestAnimationFrame(this.renderGraphics.bind(this));
    }


    render() {
        return (
            <div className="animparticule"></div>
        )
    }
}

export default canvasDraw;
