import React from "react";
import JobComponent from "../JobComponent";
import JobComponentSimple from "../JobComponentSimple";
import "./index.scss";

const JobsComponent = ({all_offers, current_jobs, dictionnary}) => {
    // J'utilise un tableau qui affichera mes jobs de sur la colonne de gauche et un autre pour la colonneDroite
    const colonneGaucheCurrent = [];
    const colonneDroiteCurrent = [];
    const colonneGaucheAll = [];
    const colonneDroiteAll = [];

    // Je push les pairs à gauche et impair à droite
    if (current_jobs && current_jobs.jobs && current_jobs.jobs.length > 0) {
        for (let i = 0; i < current_jobs.jobs.length; i++) {
            if (i % 2 === 0) {
                colonneGaucheCurrent.push(current_jobs.jobs[i]);
            } else {
                colonneDroiteCurrent.push(current_jobs.jobs[i]);
            }
        }
    }
    if (all_offers && all_offers.blocks && all_offers.blocks.length > 0) {
        for (let i = 0; i < all_offers.blocks.length; i++) {
            if (i % 2 === 0) {
                colonneGaucheAll.push(all_offers.blocks[i]);
            } else {
                colonneDroiteAll.push(all_offers.blocks[i]);
            }
        }
    }

    return (
        <>
            {((current_jobs && current_jobs.jobs && current_jobs.jobs.length > 0) || (all_offers && all_offers.blocks && all_offers.blocks.length > 0)) &&
                <div className="global__container jobscomponent__container">
                    {(current_jobs && current_jobs.jobs && current_jobs.jobs.length > 0) && (
                        <div className="now">
                            <div className="now-title">
                                <>
                                    <p></p>
                                    <h2>{current_jobs.title}</h2>
                                </>
                            </div>
                            {(colonneGaucheCurrent.length > 0 || colonneDroiteCurrent.length > 0) &&
                                <div className="now-offer">
                                    {colonneGaucheCurrent.length > 0 &&
                                        <div className="now-offer-left">
                                            {colonneGaucheCurrent.map((item, index) => (
                                                <JobComponent
                                                    key={index}
                                                    {...dictionnary}
                                                    job={item}
                                                ></JobComponent>
                                            ))}
                                        </div>
                                    }
                                    {colonneDroiteCurrent.length > 0 &&
                                        <div className="now-offer-right">
                                            {colonneDroiteCurrent.map((item, index) => (
                                                <JobComponent
                                                    key={index}
                                                    {...dictionnary}
                                                    job={item}
                                                ></JobComponent>
                                            ))}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    )}
                    {(all_offers && all_offers.blocks && all_offers.blocks.length > 0) && (
                        <div className="allday">
                            <div className="allday-title">
                                <>
                                    <p></p>
                                    <h2>{all_offers.title}</h2>
                                </>
                            </div>
                            {(colonneGaucheAll.length > 0 || colonneDroiteAll.length > 0) &&
                                <div className="allday-offer">
                                    {colonneGaucheAll.length > 0 &&
                                        <div className="allday-offer-left">
                                            {colonneGaucheAll.map((item, index) => (
                                                <JobComponentSimple key={index} job={item}></JobComponentSimple>
                                            ))}
                                        </div>
                                    }
                                    {colonneDroiteAll.length > 0 &&
                                        <div className="allday-offer-right">
                                            {colonneDroiteAll.map((item, index) => (
                                                <JobComponentSimple key={index} job={item}></JobComponentSimple>
                                            ))}
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    )}
                </div>
            }
        </>
    );
};

export default JobsComponent;
