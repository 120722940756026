import React, {useEffect} from "react";
import {useDispatch} from 'react-redux';
import {updateGlobalStorePage} from "../../store/action";
import './index.scss';
import {Link} from "react-router-dom"

import config from '../../config/404.json'

// CONTAINER
import GlobalContainer from '../../containers/GlobalContainer/index'


const NotFound = () => {
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('page-404'));
    }, [dispatch]);

    const {title, text, secondText, button} = config;

    return (
        <>
            <GlobalContainer>
                <div className="notFound__container">
                    <h2 className="title">{title}</h2>
                    <p>{text}</p>
                    <p><span>{secondText}</span></p>
                    <Link to={button.url}>
                        <button>{button.text}</button>
                    </Link>
                </div>
            </GlobalContainer>
        </>
    );
}

export default NotFound;
