import React from "react";
import "./index.scss";

const JobComponentSimple = ({ job }) => {
  return (
    <div className="job-offer-all">
      <h3 dangerouslySetInnerHTML={{ __html: job.title }}></h3>
      <div className="job-offer-all-content">
        <div className="job-offer-all-content-button">
          <a href={`mailto:${job.cta_email}`}>
            <p>{job.cta_label}</p>
          </a>
        </div>
      </div>
    </div>
  );
};

export default JobComponentSimple;
