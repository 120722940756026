import React from "react"
import { useSelector } from "react-redux";
import { Link } from "react-router-dom"
import './index.scss';


import GlobalContainer from '../../containers/GlobalContainer/index'

import { ReactComponent as Linkedin } from '../../img/linkedin.svg';
import { ReactComponent as Instagram } from '../../img/instagram.svg';
import { ReactComponent as Facebook } from '../../img/facebook.svg';


const Footer = () => {
    const globalStore = useSelector(state => state.global);
    const { social_networks, addresses } = globalStore.global;
    const { title, subtitle, titleLinks, links } = globalStore.global.footer;

    return (
        <div className="footer__container">
            <div className="footer__wrapper-top">
                <GlobalContainer>
                    <div className="footer__container-top">
                        <div className="footer-title__wrapper">
                            <h2>{title}</h2>
                            <h3>{subtitle}</h3>
                            {
                                titleLinks.map((link, key) => {
                                    return (
                                        <div key={key}>
                                            <a
                                                className="footer-link"
                                                href={link.url}>{link.text}
                                            </a>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="footer-adresses__wrapper">
                            {
                                addresses.map((address, key) => {
                                    return (
                                        <div key={key} className="adress__container">
                                            <h3>{address.city}</h3>
                                            <p>{address.street}</p>
                                            <p>{address.zipCode}</p>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </GlobalContainer>
            </div>
            <GlobalContainer>
                <div className="footer__container-bottom">
                    <div className="footer-menu__wrapper">
                        {
                            links.map(({url, text, object}, key) => {
                                return (
                                    <>
                                        {object !== 'custom' ?
                                            <Link key={key}
                                                  className="link"
                                                  to={url}>{text}
                                            </Link>
                                            :
                                            <a key={key}
                                               className="link"
                                               href={url} target="_blank">
                                                {text}
                                            </a>
                                        }
                                    </>

                                )
                            })
                        }
                    </div>
                    <div className="footer-social__wrapper">
                        {
                            social_networks.links.map((link, key) => {
                                return (
                                    <a key={key}
                                        className="link-social"
                                        href={link.url}
                                        target="_blank"
                                        rel="noopener noreferrer">{link.text === "Facebook" ?
                                            <Facebook /> : link.text === "Instagram" ? <Instagram /> : <Linkedin />}
                                    </a>
                                )
                            })
                        }
                    </div>
                </div>
            </GlobalContainer>
        </div>

    )
}

export default Footer
