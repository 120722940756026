import React, {useEffect} from "react"
import './index.scss';

import GlobalContainer from '../../containers/GlobalContainer/index'


function Banner({subject, title, backgroundVideo, srcVideo, srcPhoto, typeVideo, srcVimeo, setVideoLoaded = false}) {

    function getID(url) {
        let tab = url.split("/");
        if (tab.length > 0) {
            return tab[tab.length - 1]
        } else {
            return "";
        }
    }

    useEffect(() => {
        // if ((!backgroundVideo || (backgroundVideo && typeVideo === "vimeo" && srcVimeo)) && setVideoLoaded !== false) {
        if (!srcVideo && setVideoLoaded !== false) {
            setVideoLoaded(true);
        } else {
            setTimeout(() => {
                setVideoLoaded(true);
            }, 1200);
        }
    }, []);

    return (

        <div className={subject ? 'banner__container banner__container--project' : 'banner__container'}>
            <GlobalContainer>
                <div className="banner-content">
                    {subject && <div className="banner-overtitle">{subject}</div>}
                    {title && <h1 className="title">{title}</h1>}
                </div>
            </GlobalContainer>
            <div className="banner-overlay"/>

            {backgroundVideo ?
                (
                    typeVideo === "vimeo" && srcVimeo ?
                        (
                            window.tarteaucitron.state && window.tarteaucitron.state.vimeo ?
                                (
                                    <iframe src={srcVimeo + "?autoplay=1&loop=1&background=1"} title="video"
                                            width="100%" height="100%" frameBorder="0" className="background-video"
                                            allow="autoplay; fullscreen" allowFullScreen/>
                                ) : (
                                    <div className="vimeo_player background-video" data-videoid={getID(srcVimeo)}
                                         data-width="100%" data-height="100%"/>
                                )
                        ) : (
                            <div className="background-video">
                                <video src={srcVideo} className="background-video" muted loop autoPlay
                                       playsInline
                                    /* onLoadedData={() => {
                                  if (setVideoLoaded !== false) {
                                      setVideoLoaded(true);
                                  }
                              }}*/
                                       onLoadedMetadata={() => {
                                           if (setVideoLoaded !== false) {
                                               setVideoLoaded(true);
                                           }
                                       }}
                                />
                            </div>
                        )
                ) :
                <div className="background-photo" style={{backgroundImage: `url(${srcPhoto})`}}/>}

            <p className="banner-scroll">Scroll</p>
        </div>

    )
}

export default Banner;