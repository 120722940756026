import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { Link } from "react-router-dom"
import { useSelector } from "react-redux";
import './index.scss';

import Logo from '../../img/logo_blanc.png';
import Logo2 from '../../img/logo2_blanc.png';

import LogoBlack from '../../img/logo_noir.png';
import Logo2Black from '../../img/logo2_noir.png';


import { ReactComponent as Linkedin } from '../../img/linkedin.svg';
import { ReactComponent as Instagram } from '../../img/instagram.svg';
import { ReactComponent as Facebook } from '../../img/facebook.svg';

// COMPONENTS
import SubMenu from "../SubMenu";

// CONTAINERS
import GlobalContainer from '../../containers/GlobalContainer/index';


const Navbar = () => {

    const [showMenuItems, setShowMenuItems] = useState(true);
    const [menuOpen, setMenuOpen] = useState(false);
    const [subMenuOpen, setSubMenuOpen] = useState(false);
    const [menuSticky, setMenuSticky] = useState(false);

    const globalStore = useSelector(state => state.global);
    const { social_networks } = globalStore.global;
    const { logo, links } = globalStore.global.header;

    const [isMobile, setIsMobile] = useState(false);

    const getDevice = useCallback(() => {
        if (window.innerWidth < 992) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }
        return isMobile
    }, [isMobile])


    useLayoutEffect(() => {
        if (isMobile && menuOpen) {
            // Get original body overflow
            const originalStyle = window.getComputedStyle(document.body).overflow;
            // Prevent scrolling on mount
            document.body.style.overflow = 'hidden';
            // Re-enable scrolling when component unmounts
            return () => document.body.style.overflow = originalStyle;
        }
    }, [isMobile, menuOpen]); // Empty array ensures effect is only run on mount and unmount


    useEffect(() => {
        // did mount
        getDevice()
        window.addEventListener('resize', getDevice)
        window.addEventListener('scroll', checkMenuItemsTop)

        return () => {
            // will unmoumnt
            window.removeEventListener('resize', getDevice)
            window.removeEventListener('scroll', checkMenuItemsTop)
        }
    }, [getDevice])

    const checkMenuItemsTop = () => {
        // if (!isMobile) {
        setMenuOpen(false)
        //const navBarContainerHeight = document.querySelector('.navbar__container').clientHeight;
        // if (window.pageYOffset < navBarContainerHeight) {
        if (window.pageYOffset < 1) {
            setShowMenuItems(true)
            setMenuSticky(false)
            // } else if (window.pageYOffset >= navBarContainerHeight) {
        } else if (window.pageYOffset >= 1) {
            setShowMenuItems(false)
            setMenuSticky(true)
        }
        // }
    };

    const checkMenuOpen = () => {
        if (!menuOpen && !subMenuOpen) {
            setMenuOpen(true)
            setShowMenuItems(true)
        } else {
            setMenuOpen(false)
            setShowMenuItems(false)
            setSubMenuOpen(false);
        }
        // if (subMenuOpen) {
        //     setSubMenuOpen(false);
        // }
    };

    const handleItemClick = () => {
        setSubMenuOpen(false)
        setMenuOpen(false)
        setShowMenuItems(false)
        document.body.style.overflow = 'initial';
    }

    const handleSubItemClick = () => {
        setSubMenuOpen(!subMenuOpen);
    }

    return (

        <div
            className={`navbar__container ${menuSticky && !subMenuOpen ? 'sticky' : ''} ${menuSticky ? 'scroll' : ''} ${showMenuItems ? 'menu-items' : ''} ${subMenuOpen ? 'submenu-open' : ''} ${menuOpen ? 'mobile-open' : ''} `}>
            <Link to={logo.url} className="logo-appear">
                <img src={Logo2Black}
                    alt="Havas Digital Factory logo"
                    className="logo--black" />
                <img src={Logo2}
                    alt="Havas Digital Factory logo"
                    className="logo--white" />
            </Link>
            <GlobalContainer>
                <nav>
                    <Link className="logo-main"
                        to={logo.url}>
                        <img className="logo--black"
                            src={LogoBlack}
                            alt="Havas Digital Factory logo" />
                        <img className="logo--white"
                            src={Logo}
                            alt="Havas Digital Factory logo" />
                    </Link>
                    <div className="nav-list">
                        <div>&nbsp;</div>
                        <ul className="nav-list__items">
                            {
                                links.map((link, key) => {
                                    return (
                                        <li key={key}
                                            className={`nav-item ${link.submenu ? 'nav-item--children' : ''}`}>
                                            {
                                                link.submenu ?
                                                    (
                                                        <div className="nav-link"
                                                            onClick={() => handleSubItemClick()}>
                                                            {link.text}
                                                            <div className="submenu expertises__container">
                                                                {
                                                                    link.submenu.left && (
                                                                        <SubMenu
                                                                            extraClass={`block-expertise ${link.submenu.left.color} ${link.submenu.left.slug}`}
                                                                            title={link.submenu.left.title}
                                                                            itemsMenu={link.submenu.left.items}
                                                                            onClick={() => handleItemClick()} />
                                                                    )
                                                                }
                                                                {
                                                                    link.submenu.right && (
                                                                        <SubMenu
                                                                            extraClass={`block-expertise ${link.submenu.right.color} ${link.submenu.right.slug}`}
                                                                            title={link.submenu.right.title}
                                                                            itemsMenu={link.submenu.right.items}
                                                                            onClick={() => handleItemClick()} />
                                                                    )
                                                                }
                                                            </div>

                                                        </div>
                                                    ) : (
                                                        <Link to={link.url} className="nav-link"
                                                            onClick={() => handleItemClick()}>
                                                            {link.text}
                                                        </Link>
                                                    )
                                            }

                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className="nav-list-socials">
                            {
                                social_networks.links.map((link, key) => {
                                    return (
                                        <a key={key}
                                            className="link-social"
                                            href={link.url}
                                            rel="noopener noreferrer"
                                            target="_blank">
                                            {
                                                link.text === "Facebook" ?
                                                    <Facebook /> : link.text === "Instagram" ?
                                                        <Instagram /> :
                                                        <Linkedin />
                                            }
                                        </a>
                                    )
                                })
                            }
                        </div>
                    </div>
                </nav>
            </GlobalContainer>
            <div className={`menu-btn ${menuOpen ? 'open' : ''}`} onClick={checkMenuOpen}>
                <div className="menu-btn__burger">&nbsp;</div>
            </div>
        </div>

    )
}

export default Navbar;