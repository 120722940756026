import React from "react"
import './index.scss';


function GlobalContainer(props) {


    return (

        <div className="global__container" data-max={props.max}>
            {props.children}
        </div>

    )
}

export default GlobalContainer
