import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateGlobalStorePage } from "../../store/action";
import "./index.scss";
import { useLocation } from "react-router-dom";
import useApi from "../../utils/useApi";

// COMPONENTS
import { Loader } from "../../components/Loaders";
import Metatags from "../../components/MetasTags";
import TitleWithSocial from "../../components/TitleWithSocial/index.js";
import SliderSwiper from "../../components/SliderSwiper/index.js";
import TitleWithTextAndMail from "../../components/TitleWithTextAndMail/index.js";
import JobsComponent from "../../components/JobsComponent/index.js";

// CONTAINER
import GlobalContainer from "../../containers/GlobalContainer/index";


const Jobs = ({ _uid }) => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(updateGlobalStorePage("page-jobs"));
  }, [dispatch]);

  const location = useLocation();
  const [dataFetch, isLoaded] = useApi({
    slug: location.pathname,
    name: "page",
    _uid,
  });
  const { metas, title, hasSocial, images, mail, section__jobs } = dataFetch;

  const globalStore = useSelector((state) => state.global);
  const { social_networks } = globalStore.global;

  return (
    <>
      {isLoaded ? (
        <>
          <Metatags {...metas} />
          <GlobalContainer>
            <TitleWithSocial
              title={title}
              socials={social_networks.links}
              hasSocials={hasSocial}
            />
          </GlobalContainer>
          {images && <SliderSwiper images={images} />}
          <GlobalContainer>
            <TitleWithTextAndMail {...section__jobs}></TitleWithTextAndMail>
          </GlobalContainer>
          <JobsComponent {...section__jobs}></JobsComponent>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Jobs;
