import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateGlobalStorePage } from "../../store/action";
import { useLocation } from 'react-router-dom';
import useApi from '../../utils/useApi';
import './index.scss';

// COMPONENTS
import { Loader } from "../../components/Loaders";
import Metatags from "../../components/MetasTags";

// CONTAINER
import GlobalContainer from '../../containers/GlobalContainer/index'

const Default = ({ _uid }) => {
    let dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateGlobalStorePage('page-defaut'));
    }, [dispatch]);

    const location = useLocation();
    const [dataFetch, isLoaded] = useApi({ slug: location.pathname, name: 'page', _uid });
    const { metas, title, content } = dataFetch;

    const globalStore = useSelector(state => state.global);

    return (
        <>
            {isLoaded ? (
                <>
                    <Metatags {...metas} />
                    <GlobalContainer>

                        <div className="tpl-default__container">
                            <h1 className="title">{title}</h1>
                            <div dangerouslySetInnerHTML={{__html: content}}></div>
                        </div>

                    </GlobalContainer>
                </>
            ) : <Loader />
            }
        </>
    );
}

export default Default;
