import React from "react";
import "./index.scss";

const TitleWithTextAndMail = ({ title, uptitle, text }) => {
  return (
    <div className="title-with-text-mail__container">
      <div className="blocTitle">
        {uptitle && <h3>{uptitle}</h3>}
        {title && <h2>{title}</h2>}
      </div>
      {text && (
        <div
          className="blocDescription"
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      )}
    </div>
  );
};

export default TitleWithTextAndMail;
