import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { MatomoProvider, createInstance, useMatomo } from '@datapunt/matomo-tracker-react'

import * as serviceWorker from './serviceWorker';

// // Redux
import { Provider } from 'react-redux';
import {createStore, combineReducers} from 'redux';
import {globalReducer} from './store/Reducers/globalReducer';
import {pageReducer} from './store/Reducers/pageReducer';



const reducer = combineReducers({
    global: globalReducer,
    page: pageReducer,
})

const store = createStore(reducer);

let instance; 
if (document.cookie.includes('!matomocloud=true')) {
    instance = createInstance({
    urlBase: 'https://havas-factory.matomo.cloud/',
    siteId: 4,
});
}


ReactDOM.render(
    
    <Provider store={store}>
        <MatomoProvider value={instance}>
            <App/>
        </MatomoProvider>
    </Provider>

   
    , document.getElementById('root')
);


// const reducer = combineReducers({
//     global: globalReducer,
//     page: pageReducer,
// })

// const store = createStore(reducer);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );
//
// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
